<template>
    <div class="content-page">
      <!-- sub-menu -->
      <div class="flex flex-column align-content-center justify-content-start flex-wrap sub-menu-fixed">
        <div class="flex align-self-center align-items-center justify-content-center sub-menu">
          <div v-if="isCurrentDayInRanges()" class="flex align-items-center justify-content-center text sub-menu-item" 
          :class="{'sub-page-active':checkActive('week')}" @click="setActiveSubPage('week')">
            {{ $t("leaders.sub_menu.weekly") }}
          </div>
          <div class="flex align-items-center justify-content-center text sub-menu-item" 
          :class="{'sub-page-active':checkActive('month')}" @click="setActiveSubPage('month')">
            {{ $t("leaders.sub_menu.monthly") }}
          </div>
          <div class="flex align-items-center justify-content-center text sub-menu-item" 
          :class="{'sub-page-active':checkActive('overall')}" @click="setActiveSubPage('overall')">
            {{ $t("leaders.sub_menu.all") }}
          </div>
        </div>
      </div>
      <div class="flex flex-column leaders-content">
        <div id="leaders-div" class="flex flex-column scroll-div">
          <div v-for="(l, index) in this.rankingList" :key="l.userId" class="flex align-self-center leaders-card">
            <div class="flex align-items-center justify-content-center width-20">
              <img v-if="l.user.photoUrl" class="photo" :src="'https://'+bucket+'.s3.'+region+'.amazonaws.com/'+l.user.photoUrl" alt=""/>
              <img v-else :src="require('@/assets/icons/soccer_ball.png')"/>
            </div>
            <div class="flex align-items-center justify-content-center width-10">
              <img v-if="index==0" :src="require('@/assets/icons/trophy_gold.png')" alt="" />
              <!-- <img v-else-if="index==1" :src="require('@/assets/icons/trophy_silver.png')" alt="" />
              <img v-else-if="index==2" :src="require('@/assets/icons/trophy_bronze.png')" alt="" /> -->
              <img v-else :src="require('@/assets/icons/no_trophy.png')" alt="" />
            </div>
            <div class="flex flex-column align-items-start justify-content-center width-50">
              <div class="text-leaders"> {{ l.user.name != null ? l.user.name : $t("leaders.no_named") }} </div>
              <div class="sub-text-leaders" v-if="subPageActive == 'week'"> {{ $t("general.rank") +' '+ l.weekPosition  }} </div>
              <div class="sub-text-leaders" v-if="subPageActive == 'month'"> {{ $t("general.rank") +' '+ l.monthPosition }} </div>
              <div class="sub-text-leaders" v-if="subPageActive == 'overall'"> {{ $t("general.rank") +' '+ l.overallPosition }} </div>
            </div>
            <div class="flex align-items-center justify-content-center leaders-card-scoreboard" v-if="subPageActive == 'week'">{{  l.weekPoints }}</div>
            <div class="flex align-items-center justify-content-center leaders-card-scoreboard" v-if="subPageActive == 'month'">{{ l.monthPoints }}</div>
            <div class="flex align-items-center justify-content-center leaders-card-scoreboard" v-if="subPageActive == 'overall'">{{ l.overallPoints }}</div>
          </div>
        </div>
        <div id="scroll-sentinel"></div>
        <div style="height: 140px;"></div>
      </div>
    </div>
</template>

<script>
import i18n from "@/translation";
import { store } from "../store";
import service from "../service";

export default {
  name: "LeagueComponent",
  props: {
    param: Object
  },
  components: {
  },
  data() {
    return {
      userInfo: '',
      rankingList: '',
      subPageActive: 'month',
      page: 1,
      pageSize: 10,
      interval: ''
    }
  },
  methods: {
    async setActiveSubPage(page) {
      this.subPageActive = page;
      this.rankingList = '';
      this.page = 1;
      this.pageSize = 10;
      await this.getRankingAsync();
    },

    checkActive(page) {
      return this.subPageActive === page ? true : false;
    },
    
    getDateWithoutTime(date) {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    },

    isCurrentDayInRanges() {
      if(this.interval){
        const currentDate = new Date();
        const currentDay = currentDate.getDate();
        const periods = this.interval.split(',');
        console.log(periods)
        for (const period of periods) {
            const [start, end] = period.split('-').map(Number);
            if (currentDay >= start && currentDay <= end) {
                return false;
            }
        }
        return true;
      }
      else
        return true;
    },

    async getRankingAsync() {
      let response = await service.user.getUserRanking(this.subPageActive,this.page,this.pageSize)
      if (response && response.status === 200) {
        // data for footer
        this.userInfo = response.data.currentUserRanking;
        store.user.name = response.data.currentUserRanking.name;
        store.user.photoUrl = response.data.currentUserRanking.photoUrl;
        // store.user.points = response.data.currentUserRanking.overallPoints;
        store.user.currentPoints = response.data.currentUserRanking.overallPoints;
        store.user.currentRanking = response.data.currentUserRanking.overallRanking;
        
        if(response.data.ranking.length > 0){
          if(this.rankingList.length > 0){
            response.data["ranking"].forEach(e => {
                this.rankingList.push(e);
              });
          }else{
            this.rankingList = response.data["ranking"];
          }

          if(this.subPageActive == 'week')
            this.rankingList.sort((a,b) => { return a.weekPosition - b.weekPosition; });
          else if(this.subPageActive == 'month')
            this.rankingList.sort((a,b) => { return a.monthPosition - b.monthPosition; });
          else if(this.subPageActive == 'overall')
            this.rankingList.sort((a,b) => { return a.overallPosition - b.overallPosition; });

        }else{
          this.page -= 1
        }
        
      }
    },
    async getPrizesActivesAsync() {
      let response = await service.prizes.prizesActives();
      //show weekly if actual day is in range registered
      this.interval = response.data.filter(x => x.prizeType == "WEEKLY")[0].interval;
    },
  },
  computed: {
    language() {
      return i18n.locale;
    },
    bucket(){
      return store.AWS_BUCKET;
    },
    region(){
      return store.AWS_DEFAULT_REGION;
    },
   
  },
  async mounted() {
    await this.getRankingAsync();
    await this.getPrizesActivesAsync();

    const observer = new IntersectionObserver( async (entries, observer) => {
      for (const entry of entries) {
        if (entry.isIntersecting) {
            this.page += 1
            await this.getRankingAsync()
        }
      }
    });

    const scrollSentinel = document.querySelector("#scroll-sentinel");
    observer.observe(scrollSentinel);

    store.layout.showHeader = true;
    store.layout.showFooter = true;
    store.layout.showAccountFooter = true;
    store.layout.headerTitle = this.$t("leaders.title")
  },
};
</script>

<style scoped>
#scroll-sentinel {
  position: relative;
  bottom: 0;
}
.scroll-div{
  overflow: auto;
}
.content-page {
  font-weight: 400;
  font-family: 'Poppins';
  font-style: normal;
  background-color: #191B22;
  height: var(--appHeight);
}
.leaders-content{
  margin-bottom: 140px;
}

.sub-menu-fixed{
  width: 100%;
  position: sticky;
  top: 0px;
  z-index: 3
}

.sub-menu{
  height: 30px;
  width: 95%;
  margin-bottom: 20px;
}

.sub-menu-item{
  width: 100%;
  height: 100%;
  background-color: #282E3D;
  border-radius: 7px 7px 7px 7px;
  margin-right: 5px;
}

.sub-page-active{
  background-color: #0A57B7 !important;
}
.text{
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #B0B1CA;
}
.standings{
  width: 95%;
  height: 35px;
}
.standings-odd{
  background-color: #282E3D;
  border-radius: 10px;
}

.leaders-card{
  height: 65px;
  width: 95%;
  background-color: rgba(40, 46, 61,0.4);
  border: 1px solid #3E465A;
  box-shadow: 0px 3px 10.5px rgba(0, 0, 0, 0.443137);
  border-radius: 15px;
  margin-bottom: 10px;
}

.leaders-card:first-child {
  opacity: unset;
  border: none !important;;
  background-color: #282E3D;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='15' ry='15' stroke='%23FECD01FF' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.leaders-card:nth-child(2){
  opacity: unset;
  border: none !important;;
  background-color: #282E3D;
  border: 1px solid #3E465A;
}

.leaders-card:nth-child(3){
  opacity: unset;
  border: none !important;
  background-color: #282E3D;
  border: 1px solid #3E465A;
}

.photo{
  width: 37px;
  height: 37px;
  border-radius: 50%;
}

.leaders-card-scoreboard{
  width:20%;
  font-weight: 700;
  font-size: 12px;
  color: #B0B1CA;
}

.leaders-card:first-child .leaders-card-scoreboard{
  color: #FFCD00;
}

.leaders-card:nth-child(2) .leaders-card-scoreboard{
  color: #FFCD00;
}

.leaders-card:nth-child(3) .leaders-card-scoreboard{
  color: #FFCD00;
}

.text-leaders{
  font-weight: 700;
  font-size: 12px;
  color: #FFFFFF;
}

.sub-text-leaders{
  font-weight: 300;
  font-size: 10px;
  color: #FFFFFF
}

.leaders-card:first-child .sub-text-leaders{
  color: #FFCD00 !important;
}

.width-10{
  width: 10%;
}
.width-20{
  width: 20%;
}
.width-50{
  width: 50%;
  padding-left: 5px;
}
</style>
